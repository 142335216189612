export const removeUnneeded = (obj: { [key: string]: any }) => {
    for (const k in obj) {
        if (["", null, undefined].includes(obj[k])) delete obj[k];
    }
};

export const trimStringToArray = (str?: string): string[] => {
    return (
        (str &&
            str
                .split(",")
                .map((eachDocName) => eachDocName.trim())
                .filter((eachDocName) => eachDocName)) ||
        []
    );
};

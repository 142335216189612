import { Config } from "../../Config";
import {
    BaseController,
    sdkFailureIconImage,
    sdkSuccessIconImage
} from "./baseController";
import {
    ApplicantData,
    CollectionVerificationRequestDto,
    FacetecLivenessResponseDto,
    FacetecOCRResponseDto,
    OCRScanData,
    SDKVerificationResponseDto,
    SdkProduct
} from "../interfaces";
import {
    FaceTecIDScanResult,
    FaceTecSessionResult
} from "../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { FaceTecSDK } from "../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import { trimStringToArray } from "../utilities/utils";

export class CollectionController extends BaseController {
    readonly controllerType = "collection";
    lastSumbittedFormData: CollectionVerificationRequestDto = null as any;

    async startVerification(): Promise<boolean> {
        const collectionData = await this.apiRequest<SdkProduct>(
            `${Config.ApiBaseUrl}/v1/sdk/products/${
                this.sdkElementData.productCode || "none"
            }`,
            "GET",
            {},
            true,
            this.sdkInitializationData?.token?.accessToken
        ).catch((error) => {
            this.setSdkStatusElement(
                `<div class="sdkErrorMessage">${
                    error.message ||
                    "Unable to initialize a verification process at the moment"
                }</div>`
            );
            return null;
        });

        return this.processCollectionData(collectionData as SdkProduct);
    }

    async processCollectionData(collectionData: SdkProduct): Promise<boolean> {
        if (collectionData) this.renderSdkRequestBody(collectionData);
        return !!collectionData;
    }

    onComplete(
        sessionResult?: FaceTecSessionResult,
        idScanResult?: FaceTecIDScanResult,
        _latestNetworkResponseStatus?: number,
        _latestIDScanOCRResult?: OCRScanData
    ) {
        if (this.lastSumbittedFormData) {
            this.lastSumbittedFormData.xUserAgent =
                FaceTecSDK.createFaceTecAPIUserAgentString(
                    sessionResult?.sessionId || ""
                );
            this.lastSumbittedFormData.data.externalDatabaseRefID =
                this.getLatestEnrollmentIdentifier();
            if (idScanResult) {
                this.lastSumbittedFormData.data.scanResult = {
                    idScanFrontImage: idScanResult.frontImages[0],
                    idScanBackImage: idScanResult.backImages[0],
                    idScan: idScanResult.idScan ?? "",
                    sessionId: idScanResult.sessionId,
                    minMatchLevel: 3
                };

                if (this.sdkElementData.ocrAcceptedDocuments) {
                    this.lastSumbittedFormData.data.acceptedDocuments =
                        trimStringToArray(
                            this.sdkElementData.ocrAcceptedDocuments
                        );
                }
            }

            if (sessionResult) {
                this.lastSumbittedFormData.data.sessionResult = {
                    faceScan: sessionResult.faceScan,
                    lowQualityAuditTrailImage:
                        sessionResult.lowQualityAuditTrail[0],
                    auditTrailImage: sessionResult.auditTrail[0]
                };
            }
        }

        this.lastSumbittedFormData.data.logId = this.logId ?? undefined;

        this.submitVerification();

        return null as any;
    }

    async submitVerification(formElement?: HTMLFormElement) {
        this.setSdkStatusElement("");

        if (!this.finalSubmitButton) {
            this.finalSubmitButton = window.document.getElementById(
                "sdkVerificationFormSubmitButton"
            ) as HTMLButtonElement;
        }
        this.updateFinalSubmitButton("Please wait...", true);

        if (!this.lastSumbittedFormData) {
            if (!formElement) return;

            const requestData: CollectionVerificationRequestDto = {
                source: this.sdkSource,
                xUserAgent: window.navigator.userAgent,
                customerReference: this.sdkElementData.customerReference,
                data: {
                    logId: this.logId ?? undefined
                },
                applicant: {} as any
            } as any;

            for (const key in formElement.elements) {
                if (String(key) != Number(key).toString()) continue;
                const element: HTMLInputElement =
                    formElement.elements.namedItem(
                        (formElement.elements[key] as any).name
                    ) as any;
                if (element?.name && !element.disabled) {
                    if (String(element.id).indexOf("fields_") === 0) {
                        const fieldName = String(element.id).replace(
                            "fields_",
                            ""
                        );
                        if (element.classList.contains("sdkBooleanField")) {
                            requestData.data[fieldName] =
                                String(element.value || "").trim() == "yes";
                        } else {
                            requestData.data[fieldName] =
                                String(element.value || "").trim() || undefined;
                        }
                    } else if (
                        String(element.id).indexOf("applicantFields_") === 0
                    ) {
                        const fieldName = String(element.id).replace(
                            "applicantFields_",
                            ""
                        ) as keyof ApplicantData;
                        if (element.readOnly) {
                            requestData.applicant[fieldName] =
                                this.sdkElementData.applicantData[fieldName]!;
                        }
                        if (element.classList.contains("sdkBooleanField")) {
                            requestData.applicant[fieldName] = (String(
                                element.value || ""
                            ).trim() == "yes") as any;
                        } else {
                            requestData.applicant[fieldName] = (String(
                                element.value || ""
                            ).trim() || undefined) as any;
                        }
                    } else {
                        (requestData as any)[element.name] =
                            element.value || undefined;
                    }
                }
            }

            if (!requestData.productCode) {
                this.setSdkStatusElement(
                    `<div class="sdkErrorMessage">Please select a verification type</div>`
                );
                return;
            }

            this.lastSumbittedFormData = requestData;
            const productCode = this.sdkElementData.productCode!;

            if (
                this.productCodeHasOcr(productCode) ||
                this.productCodeHasLiveness(productCode) ||
                this.productCodeHasFaceMatch(productCode)
            ) {
                this.launchFaceTec(productCode);
                return;
            }
        }
        
        // update organisation extra data into request data if available
        this.lastSumbittedFormData.organisationExtraData = this.sdkElementData.extraData || undefined

        if(JSON.stringify(this.lastSumbittedFormData.applicant) == '{}') this.lastSumbittedFormData.applicant = undefined as any;
        
        let response: SDKVerificationResponseDto =
            await this.apiRequest<SDKVerificationResponseDto>(
                `${Config.ApiBaseUrl}/v1/sdk/verifications`,
                this.logId ? "PUT" : "POST",
                this.lastSumbittedFormData,
                true,
                this.sdkInitializationData?.token?.accessToken
            ).catch((error) => error);

        let customEvent: Event = new Event("qoreid:event");
        if (response?.id) {
            let htmlString = `<div>
                <img src="${sdkSuccessIconImage}" alt="Successful" width="80" />
                <div class="sdkSuccessMessage">Verification Submitted Successfully</div>
                <br>
                <div>
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">Close</button>
                </div>
            </div>`;

            const faceTecDataLiveness = response?.meta
                ?.facetec as FacetecLivenessResponseDto;
            if (faceTecDataLiveness?.isLive === false) {
                if (
                    faceTecDataLiveness.canRetryLiveness &&
                    this.currentProductCode
                ) {
                    this.launchFaceTec(this.currentProductCode);
                    return;
                } else if (faceTecDataLiveness.canRetryLiveness === false) {
                    htmlString = `
                    <div>
                        <img src="${sdkFailureIconImage}" alt="Verification Failed" width="80" />
                        <div class="sdkSuccessMessage">Verification Error</div>
                        <div class="sdkErrorMessage">Liveness is not detected and try limit exceeded.</div>
                        <br>
                        <div>
                            <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                        </div>
                    </div>`;
                }
            }

            const faceTecDataOCR: FacetecOCRResponseDto =
                faceTecDataLiveness as any;
            if (faceTecDataOCR?.success === false) {
                if (faceTecDataOCR.canRetryOcr) {
                    this.launchFaceTec(this.currentProductCode, true);
                    return;
                } else {
                    htmlString = `
                    <div>
                        <img src="${sdkFailureIconImage}" alt="Verification Failed" width="80" />
                        <div class="sdkSuccessMessage">Verification Error</div>
                        <div class="sdkErrorMessage">Document-scan failed and try-limit exceeded</div>
                        <br>
                        <div>
                            <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                        </div>
                    </div>`;
                }
            }

            this.setSdkBodyElement(htmlString);
            customEvent = new CustomEvent("qoreid:verificationSubmitted", {
                detail: response
            });
            this.redirectingData = {
                type: "qoreid:verificationSubmitted",
                detail: response
            };
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.submittedEventTrigger(customEvent);
        } else {
            this.setSdkBodyElement(`
            <div>
                <img src="${sdkFailureIconImage}" alt="Verification Failed" width="80" />
                <div class="sdkSuccessMessage">Verification Error</div>
                <div class="sdkErrorMessage">${
                    (response as any)?.message || "error verifying your request"
                }</div>
                <br>
                <div>
                    <!-- <button class="sdkButton" onclick="QoreIDWebSdk.initialize(QoreIDWebSdk)">Retry</button> -->
                    <button class="sdkButton sdkButtonInline" onclick="QoreIDWebSdk.closeVerification()">OK</button>
                </div>
            </div>
            `);
            customEvent = new CustomEvent<SDKVerificationResponseDto>(
                "qoreid:verificationError",
                { detail: response }
            );
            this.redirectingData = {
                type: "qoreid:verificationError",
                detail: response
            };
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.sdkElementData.errorEventTrigger(customEvent);
        }
        this.toggleWaiterElement(false);
        this.triggerTopMainElementEvent(customEvent);
        this.lastSumbittedFormData = null as any;
    }
}

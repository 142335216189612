const qoreIdScriptUrl = String(
    (document.querySelector('script[src*="/qoreid-sdk/qoreid.js"]') as any)
        ?.src || ""
);
(window as any).qoreIdSdkBaseUrl =
    qoreIdScriptUrl.replace("/qoreid.js", "") || "/";

import { CollectionController } from "./controllers/collectionController";
import { WorkflowController } from "./controllers/workflowController";
import { IQoreIdButton } from "./interfaces";

(window as any).QoreIdRegenerateSDK = () => {
    const qoreIdSdkPostedData: IQoreIdButton = (window as any)
        .QoreIdSdkPostedData;
    const mainElement = document.getElementById("QoreIDButton") as HTMLElement;
    if (!mainElement) {
        console.error("QoreID button is missing on this page");
        return;
    }

    // parsing flowId
    const flowId = Number(
        (qoreIdSdkPostedData
            ? qoreIdSdkPostedData.flowId
            : mainElement.getAttribute("flowId")) || 0
    );
    if (flowId) {
        /* ******************************************************
         ************* Workflow start implementation ********** */
        (window as any).QoreIDWebSdk = new WorkflowController();
    } else {
        // parsing client secret
        const productCode =
            (qoreIdSdkPostedData
                ? qoreIdSdkPostedData.productCode
                : mainElement.getAttribute("productCode")) || "";
        if (!productCode) {
            console.error("ProductCode is missing");
            return;
        }

        /* ******************************************************
         ************* Collection start implementation ********** */
        (window as any).QoreIDWebSdk = new CollectionController();
    }
    console.info(
        `Running QoreIdS SDK in ${
            (window as any).QoreIDWebSdk.controllerType
        } mode`
    );
    window.addEventListener(
        "message",
        (window as any).QoreIDWebSdk.topWindowMessageHandler
    );
};

(window as any).QoreIdRegenerateSDK();

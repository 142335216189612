import { FaceTecSDK } from "./core-sdk/FaceTecSDK.js/FaceTecSDK";
import { IFaceTecCredentials } from "./src/interfaces";
type IFaceTecSDK = typeof FaceTecSDK;

const isQoreSDKProduction = ["www.qoreid.com", "dashboard.qoreid.com"].includes(
    window.location.hostname
);

const isQoreSDKStaging =
    "stage-qoreid.verifynow.ng" === window.location.hostname;
export let getQoreIdPrimaryColor = "#417FB2";

class Configuration {
    BaseSitePath = "/qoreid-sdk";
    ApiBaseUrl = isQoreSDKProduction
        ? "https://api.qoreid.com"
        : isQoreSDKStaging
        ? "https://stage-qoreapi.verifynow.ng"
        : "https://dev-qoreapi.verifynow.ng";
    allowedWhiteLabel = false;

    private _qoreIdPrimaryColor = "#417FB2";

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    public BaseURL = `${this.ApiBaseUrl}/v1/sdk/biometrics`;

    public currentLowLightCustomization: any;
    public currentDynamicDimmingCustomization: any;

    // -------------------------------------
    // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
    // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's Design Showcase,
    // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
    // Configuration Wizard.
    public wasSDKConfiguredWithConfigWizard = true;

    // Set the feature flag(s)
    public SDKInIFrameFeatureFlag = {
        ac_ziif: "991d6c1c-5e5c-4e32-89c7-1ddfac46572e"
    };
    public currentCustomization = new FaceTecSDK.FaceTecCustomization([
        this.SDKInIFrameFeatureFlag
    ]);

    // -------------------------------------
    // Convenience method to initialize the FaceTec SDK.
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    initializeFromAutogeneratedConfig(
        FaceTecSDK: IFaceTecSDK,
        callback: Function,
        faceTecCredentials: IFaceTecCredentials
    ) {
        if (isQoreSDKProduction) {
            FaceTecSDK.initializeInProductionMode(
                faceTecCredentials.productionKeyText,
                faceTecCredentials.deviceKeyIdentifier,
                faceTecCredentials.publicFaceScanEncryptionKey,
                (initializedSuccessfully: boolean) => {
                    callback(initializedSuccessfully);
                }
            );
        } else {
            FaceTecSDK.initializeInDevelopmentMode(
                faceTecCredentials.deviceKeyIdentifier,
                faceTecCredentials.publicFaceScanEncryptionKey,
                (initializedSuccessfully: boolean) => {
                    callback(initializedSuccessfully);
                }
            );
        }
    }

    get qoreIdPrimaryColor(): string {
        return this._qoreIdPrimaryColor;
    }

    set qoreIdPrimaryColor(value: string) {
        this._qoreIdPrimaryColor = value;
    }

    // This app can modify the customization to demonstrate different look/feel preferences
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    retrieveConfigurationWizardCustomization(FaceTecSDK: IFaceTecSDK) {
        var sdkImageDirectory =
            (window as any).qoreIdSdkBaseUrl + "/core-sdk/FaceTec_images/";

        // For Color Customization
        var outerBackgroundColor = "#ffffff";
        var frameColor = "#ffffff";
        var borderColor = this._qoreIdPrimaryColor;
        var ovalColor = this._qoreIdPrimaryColor;
        var dualSpinnerColor = this._qoreIdPrimaryColor;
        var textColor = this._qoreIdPrimaryColor;
        var buttonAndFeedbackBarColor = this._qoreIdPrimaryColor;
        var buttonAndFeedbackBarTextColor = "#ffffff";
        var buttonColorHighlight = this._qoreIdPrimaryColor;
        var buttonColorDisabled = "#B9CCDE";

        // For Frame Corner Radius Customization
        let frameCornerRadius = "20px";

        // For Cancel Button Customization
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonLocation =
            FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;

        // For image Customization
        var yourAppLogoImage =
            sdkImageDirectory +
            (this.allowedWhiteLabel
                ? "FaceTec_your_app_logo_transparent.png"
                : "FaceTec_your_app_logo.png");
        var securityWatermarkImage =
            FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec;

        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();

        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius =
            frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        defaultCustomization.overlayCustomization.brandingImage =
            yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor =
            outerBackgroundColor;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors =
            frameColor;
        defaultCustomization.guidanceCustomization.foregroundColor = textColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor =
            buttonColorDisabled;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor =
            buttonColorHighlight;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor =
            borderColor;
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor =
            borderColor;

        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 =
            dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 =
            dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor =
            buttonAndFeedbackBarTextColor;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage =
            cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location =
            cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(
            securityWatermarkImage
        );

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors =
            frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor =
            textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor =
            buttonAndFeedbackBarColor;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors =
            frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor =
            textColor;
        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors =
            frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.captureScreenForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor =
            buttonColorDisabled;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor =
            buttonColorHighlight;
        defaultCustomization.idScanCustomization.buttonTextNormalColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor =
            frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor =
            borderColor;

        // Set Initial Loading Customization
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor =
            buttonAndFeedbackBarColor;

        return defaultCustomization;
    }

    retrieveLowLightConfigurationWizardCustomization(FaceTecSDK: any) {
        const defaultCustomization =
            this.retrieveConfigurationWizardCustomization(FaceTecSDK);
        this.currentLowLightCustomization = defaultCustomization;
        return defaultCustomization;
    }

    retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK: any) {
        var defaultCustomization =
            this.retrieveConfigurationWizardCustomization(FaceTecSDK);
        this.currentDynamicDimmingCustomization = defaultCustomization;
        return defaultCustomization;
    }
}

export const Config = new Configuration();

export enum FlowStepState {
    COMPLETE = "Complete",
    PENDING = "Pending",
    EMPTY = ""
}

export enum ServiceCode {
    BIOMETRICS = "biometrics",
    IDENTITY_DOCUMENT = "identity_document",
    DIGITAL_IDENTITY = "digital_identity",
    ADDRESS = "address"
}

export enum OCRScanDataTemplateType {
    PASSPORT = "Passport"
}

export enum OCRScanDataGroupKey {
    USER_INFO = "userInfo",
    ID_INFO = "idInfo",
    ADDRESS_INFO = "addressInfo",
    SECONDARY_USER_INFO = "secondaryUserInfo",
    CUSTOM_FIELDS = "customFields"
}

export const PROD_KEYWORD_FACE_VERIFICATION = "face_";

export const VERIFICATION_PRODUCT_CODES: { [key: string]: string } = {
    NIN: "verify_nin_sub",
    PASSPORT_NG: "verify_passport_ng_sub",
    PASSPORT_KE: "verify_passport_ke_sub",
    NIN_PHONE: "verify_nin_phone_sub",
    VIRTUAL_NIN: "verify_virtual_nin_sub",
    BVN_BASIC: "verify_bvn_basic_sub",
    BVN_PREMIUM: "verify_bvn_premium_sub",
    BVN_BOOLEAN: "verify_bvn_boolean_sub",
    DRIVERS_LICENSE: "verify_drivers_license_sub",
    TIN: "verify_tin_sub",
    CAC_BASIC: "verify_cac_basic_sub",
    CAC_PREMIUM: "verify_cac_premium_sub",
    VIN: "verify_vin_sub",
    NUBAN: "verify_nuban_sub",
    NUBAN_BVN: "verify_bvn_nuban_sub",
    LICENSE_PLATE_BASIC: "verify_license_plate_basic_sub",
    LICENSE_PLATE_PREMIUM: "verify_license_plate_premium_sub",
    INDIVIDUAL_ADDRESS: "verify_individual_address_sub",
    BUSINESS_ADDRESS: "verify_business_address_sub",
    EMPLOYMENT: "verify_employment_sub",
    GUARANTOR: "verify_guarantor_sub",
    PROPERTY: "verify_property_sub",
    VEHICLE: "verify_vehicle_sub",
    VEHICLE_SELF: "verify_vehicle_self_sub",
    FACE_VERIFICATION_BVN: "verify_face_verification_bvn_sub",
    FACE_VERIFICATION_NIN: "verify_face_verification_nin_sub",
    FACE_VERIFICATION_VNIN: "verify_face_verification_vnin_sub",
    FACE_VERIFICATION_DRIVERS_LICENSE:
        "verify_face_verification_drivers_license_sub",
    FACE_VERIFICATION_PASSPORT: "verify_face_verification_passport_ng_sub",
    FACE_VERIFICATION_PASSPORT_KE: "verify_face_verification_passport_ke_sub",
    LIVENESS: "verify_liveness_sub",
    OCR: "verify_ocr_sub",
    LIVENESS_BVN: "verify_liveness_bvn_sub",
    
    LIVENESS_PASSPORT: "verify_liveness_passport_ng_sub",
    LIVENESS_VNIN: "verify_liveness_vnin_sub",
    LIVENESS_PASSPORT_KE: "verify_liveness_passport_ke_sub",
    LIVENESS_DRIVERS_LICENSE: "verify_liveness_drivers_license_sub",
    LIVENESS_NIN: "verify_liveness_nin_sub",
    LIVENESS_NIN_PHONE: "verify_liveness_nin_phone_sub",
    LIVENESS_OCR: "verify_liveness_ocr_sub",
    KENYA_ID: "verify_kenya_id_sub",
    ALIEN_CARD: "verify_alien_card_sub",
    BRS: "verify_brs_sub"
};

export const DEFAULT_SDK_APPLICANT_DATA = {
    firstname: "Olayode",
    middlename: "",
    lastname: "Ezekiel",
    gender: "male",
    dob: "1975-07-07",
    phone: "07038762683",
    email: "kielsoft@gmail.com"
};

export const DEFAULT_SDK_IDENTITY_DATA = {
    idType: "bvn",
    idNumber: "28689965495",
    requiredIdType: ""
};

export const DEFAULT_SDK_ADDRESS_DATA = {
    address: "5 alomaja",
    city: "Ikorodu",
    lgaName: "Ikorodu"
};

interface OrganisationExtraData {
    organisationId: string
    organisationName: string
    requestSource: string
    internalReferenceId: string
}

export const DEFAULT_SDK_ELEMENT_DATA = {
    clientId: "EV5KO5D2QSF3YNL7K543",
    flowId: "108",
    isFlow: true,
    productCode: "bvn_boolean",
    customerReference: "2347038762683",
    applicantData: DEFAULT_SDK_APPLICANT_DATA,
    identityData: DEFAULT_SDK_IDENTITY_DATA,
    addressData: DEFAULT_SDK_ADDRESS_DATA,
    extraData: undefined as any as OrganisationExtraData,
    ocrAcceptedDocuments:
        "DRIVERS_LICENSE_NGA,VOTERS_CARD_NGA,NIN_SLIP_NGA,PASSPORT_NGA"
};

import { Config } from "../../../Config";
import { FaceTecSDK } from "../../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import type {
    FaceTecSessionResult,
    FaceTecFaceScanResultCallback,
    FaceTecIDScanResult,
    FaceTecIDScanResultCallback,
    FaceTecFaceScanProcessor,
    FaceTecIDScanProcessor
} from "../../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { CollectionController } from "../../controllers/collectionController";
import {
    FacetecLivenessResponseDto,
    SDKVerificationResponseDto
} from "../../interfaces";
import { trimStringToArray } from "../../utilities/utils";

export class CollectionProcessor
    implements FaceTecFaceScanProcessor, FaceTecIDScanProcessor
{
    public latestSessionResult: FaceTecSessionResult;
    latestNetworkRequest: XMLHttpRequest = new XMLHttpRequest();
    withOCR = false;

    constructor(
        public sessionToken: string,
        public appControllerReference: CollectionController
    ) {
        this.latestSessionResult = null as any;
    }

    launchLiveness(withOCR = false) {
        this.withOCR = withOCR;

        if (this.withOCR) {
            FaceTecSDK.FaceTecCustomization.setIDScanUploadMessageOverrides(
                "Uploading<br/>Encrypted<br/>ID Scan", // Upload of ID front-side has started.
                "Still Uploading...<br/>Slow Connection", // Upload of ID front-side is still uploading to Server after an extended period of time.
                "Upload Complete", // Upload of ID front-side to the Server is complete.
                "Processing<br/>ID Scan", // Upload of ID front-side is complete and we are waiting for the Server to finish processing and respond.
                "Uploading<br/>Encrypted<br/>Back of ID", // Upload of ID back-side has started.
                "Still Uploading...<br/>Slow Connection", // Upload of ID back-side is still uploading to Server after an extended period of time.
                "Upload Complete", // Upload of ID back-side to Server is complete.
                "Processing<br/>Back of ID", // Upload of ID back-side is complete and we are waiting for the Server to finish processing and respond.
                "Uploading<br/>Your Confirmed Info", // Upload of User Confirmed Info has started.
                "Still Uploading...<br/>Slow Connection", // Upload of User Confirmed Info is still uploading to Server after an extended period of time.
                "Upload Complete", // Upload of User Confirmed Info to the Server is complete.
                "Processing" // Upload of User Confirmed Info is complete and we are waiting for the Server to finish processing and respond.
            );
        }

        this.appControllerReference.inIFrame
            ? new FaceTecSDK.FaceTecSessionFromIFrame(this, this.sessionToken)
            : new FaceTecSDK.FaceTecSession(this, this.sessionToken);

        return this;
    }

    launchIDScanOnly() {
        FaceTecSDK.FaceTecCustomization.setIDScanUploadMessageOverrides(
            "Uploading<br/>Encrypted<br/>ID Scan", // Upload of ID front-side has started.
            "Still Uploading...<br/>Slow Connection", // Upload of ID front-side is still uploading to Server after an extended period of time.
            "Upload Complete", // Upload of ID front-side to the Server is complete.
            "Processing<br/>ID Scan", // Upload of ID front-side is complete and we are waiting for the Server to finish processing and respond.
            "Uploading<br/>Encrypted<br/>Back of ID", // Upload of ID back-side has started.
            "Still Uploading...<br/>Slow Connection", // Upload of ID back-side is still uploading to Server after an extended period of time.
            "Upload Complete", // Upload of ID back-side to Server is complete.
            "Processing<br/>Back of ID", // Upload of ID back-side is complete and we are waiting for the Server to finish processing and respond.
            "Uploading<br/>Your Confirmed Info", // Upload of User Confirmed Info has started.
            "Still Uploading...<br/>Slow Connection", // Upload of User Confirmed Info is still uploading to Server after an extended period of time.
            "Upload Complete", // Upload of User Confirmed Info to the Server is complete.
            "Processing" // Upload of User Confirmed Info is complete and we are waiting for the Server to finish processing and respond.
        );

        const processor = new CollectionProcessor(
            this.sessionToken,
            this.appControllerReference
        );
        (processor as any).processSessionResultWhileFaceTecSDKWaits = undefined;

        this.appControllerReference.inIFrame
            ? new FaceTecSDK.FaceTecSessionFromIFrame(
                  processor as FaceTecIDScanProcessor,
                  this.sessionToken
              )
            : new FaceTecSDK.FaceTecSession(
                  processor as FaceTecIDScanProcessor,
                  this.sessionToken
              );

        return this;
    }

    public processSessionResultWhileFaceTecSDKWaits = (
        sessionResult: FaceTecSessionResult,
        faceScanResultCallback: FaceTecFaceScanResultCallback
    ): void => {
        this.latestSessionResult = sessionResult;
        let errorMessage = "";
        if (
            sessionResult.status !==
            FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
        ) {
            let errorMessage =
                "The capture session was not successful. Please try again.";
            console.debug(
                "Session was not completed successfully, cancelling.  Session Status: " +
                    FaceTecSDK.FaceTecSessionStatus[sessionResult.status]
            );

            switch (sessionResult.status) {
                case FaceTecSDK.FaceTecSessionStatus.UserCancelled:
                case FaceTecSDK.FaceTecSessionStatus
                    .UserCancelledViaClickableReadyScreenSubtext:
                case FaceTecSDK.FaceTecSessionStatus
                    .UserCancelledFromRetryGuidance:
                case FaceTecSDK.FaceTecSessionStatus
                    .UserCancelledWhenAttemptingToGetCameraPermissions:
                    errorMessage =
                        "The capture session was canceled. Please try again";
                    break;

                default:
                    errorMessage =
                        "The camera capture session was not completed.";
                    break;
            }

            this.appControllerReference.setSdkStatusElement(
                `<div class="sdkErrorMessage">${errorMessage}</div>`
            );

            this.appControllerReference.lastSumbittedFormData = null as any;
            this.appControllerReference.updateFinalSubmitButton("", false);
            faceScanResultCallback.cancel();
            return;
        }

        const lastSumbittedFormData =
            this.appControllerReference.lastSumbittedFormData;

        if (
            lastSumbittedFormData &&
            this.appControllerReference.controllerType == "collection"
        ) {
            lastSumbittedFormData.xUserAgent =
                FaceTecSDK.createFaceTecAPIUserAgentString(
                    sessionResult?.sessionId || ""
                );
            lastSumbittedFormData.data.externalDatabaseRefID =
                this.appControllerReference.getLatestEnrollmentIdentifier();
        }

        if (!this.withOCR) {
            // Liveness Only
            this.appControllerReference.onComplete(sessionResult);
            faceScanResultCallback.cancel();
        } else {
            // Liveness with OCR
            const requestData: any = {
                ...lastSumbittedFormData,
                data: {
                    ...lastSumbittedFormData.data,
                    sessionId: this.latestSessionResult.sessionId,
                    logId: this.appControllerReference.logId || undefined,
                    acceptedDocuments: trimStringToArray(
                        this.appControllerReference.sdkElementData
                            .ocrAcceptedDocuments
                    ),
                    sessionResult: {
                        faceScan: sessionResult.faceScan,
                        lowQualityAuditTrailImage:
                            (sessionResult.lowQualityAuditTrail || [""])[0],
                        auditTrailImage: (sessionResult!.auditTrail || [""])[0]
                    }
                }
            };
            //
            // Part 5:  Make the Networking Call to Your Servers.  Below is just example code, you are free to customize based on how your own API works.
            //
            this.latestNetworkRequest = new XMLHttpRequest();
            this.latestNetworkRequest.open(
                "POST",
                Config.ApiBaseUrl + "/v1/sdk/verifications"
            );
            this.latestNetworkRequest.setRequestHeader(
                "Content-Type",
                "application/json"
            );
            this.latestNetworkRequest.setRequestHeader(
                "Authorization",
                "Bearer " +
                    this.appControllerReference.sdkInitializationData.token
                        .accessToken
            );

            this.latestNetworkRequest.setRequestHeader(
                "X-Device-Key",
                this.appControllerReference.sdkInitializationData
                    .faceTecCredentials.deviceKeyIdentifier
            );
            this.latestNetworkRequest.setRequestHeader(
                "X-User-Agent",
                FaceTecSDK.createFaceTecAPIUserAgentString(
                    sessionResult.sessionId as string
                )
            );

            this.latestNetworkRequest.onreadystatechange = (): void => {
                //
                // Part 6:  In our Sample, we evaluate a boolean response and treat true as was successfully processed and should proceed to next step,
                // and handle all other responses by cancelling out.
                // You may have different paradigms in your own API and are free to customize based on these.
                //

                if (
                    this.latestNetworkRequest.readyState === XMLHttpRequest.DONE
                ) {
                    try {
                        const responseJSON = JSON.parse(
                            this.latestNetworkRequest.responseText
                        ) as SDKVerificationResponseDto;
                        if (
                            (responseJSON as any).error &&
                            (responseJSON as any).message &&
                            (responseJSON as any).devMessage
                        ) {
                            const internalError = new Error(
                                (responseJSON as any).message
                            );
                            (internalError as any).devMessage = (
                                responseJSON as any
                            ).devMessage;
                            throw internalError;
                        }
                        const faceTecData = responseJSON?.meta
                            ?.facetec as FacetecLivenessResponseDto;
                        if (responseJSON.id)
                            this.appControllerReference.logId = responseJSON.id;
                        if (faceTecData?.externalDatabaseRefID)
                            this.appControllerReference.latestEnrollmentIdentifier =
                                faceTecData.externalDatabaseRefID;

                        // In v9.2.0+, we key off a new property called wasProcessed to determine if we successfully processed the Session result on the Server.
                        // Device SDK UI flow is now driven by the proceedToNextStep function, which should receive the scanResultBlob from the Server SDK response.
                        if (faceTecData?.isLive === true) {
                            // Demonstrates dynamically setting the Success Screen Message.
                            FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
                                "Liveness\nConfirmed"
                            );

                            // In v9.2.0+, simply pass in scanResultBlob to the proceedToNextStep function to advance the User flow.
                            // scanResultBlob is a proprietary, encrypted blob that controls the logic for what happens next for the User.
                            faceScanResultCallback.proceedToNextStep(
                                faceTecData.scanResultBlob
                            );
                        } else {
                            // CASE:  UNEXPECTED response from API.  Our Sample Code keys off a wasProcessed boolean on the root of the JSON object --> You define your own API contracts with yourself and may choose to do something different here based on the error.

                            if (faceTecData?.canRetryLiveness) {
                                faceScanResultCallback.retry();
                                return;
                            }
                            errorMessage =
                                "Liveness is not detected and try limit exceeded";
                            console.debug(errorMessage);
                            this.appControllerReference.setSdkStatusElement(
                                `<div class="sdkErrorMessage">${errorMessage}</div>`
                            );
                            this.appControllerReference.updateFinalSubmitButton();
                            faceScanResultCallback.cancel();
                        }
                    } catch (error: any) {
                        // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself and may choose to do something different here based on the error.  Solid server-side code should ensure you don't get to this case.
                        errorMessage = error.devMessage
                            ? error.message
                            : "API response error, please try again later";
                        console.debug(errorMessage);
                        this.appControllerReference.setSdkStatusElement(
                            `<div class="sdkErrorMessage">${errorMessage}</div>`
                        );
                        this.appControllerReference.updateFinalSubmitButton();
                        faceScanResultCallback.cancel();
                    }
                }
            };

            this.latestNetworkRequest.onerror = (): void => {
                // CASE:  Network Request itself is erroring --> You define your own API contracts with yourself and may choose to do something different here based on the error.
                errorMessage = "Network error, please try again later";
                console.debug(errorMessage);
                this.appControllerReference.setSdkStatusElement(
                    `<div class="sdkErrorMessage">${errorMessage}</div>`
                );
                this.appControllerReference.updateFinalSubmitButton();
                faceScanResultCallback.cancel();
            };

            //
            // Part 7:  Demonstrates updating the Progress Bar based on the progress event.
            //
            this.latestNetworkRequest.upload.onprogress = (
                event: ProgressEvent
            ): void => {
                var progress = event.loaded / event.total;
                faceScanResultCallback.uploadProgress(progress);
            };

            //
            // Part 8:  Actually send the request.
            //
            var jsonStringToUpload = JSON.stringify(requestData);
            this.latestNetworkRequest.send(jsonStringToUpload);

            //
            // Part 9:  For better UX, update the User if the upload is taking a while.  You are free to customize and enhance this behavior to your liking.
            //
            window.setTimeout(() => {
                if (
                    this.latestNetworkRequest.readyState === XMLHttpRequest.DONE
                ) {
                    return;
                }

                faceScanResultCallback.uploadMessageOverride(
                    "Still Uploading..."
                );
            }, 6000);
        }
    };

    public processIDScanResultWhileFaceTecSDKWaits = (
        idScanResult: FaceTecIDScanResult,
        idScanResultCallback: FaceTecIDScanResultCallback
    ): void => {
        if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
            let errorMessage =
                "ID Scan was not completed successfully, try again later.";

            this.appControllerReference.setSdkStatusElement(
                `<div class="sdkErrorMessage">${errorMessage}</div>`
            );

            this.appControllerReference.lastSumbittedFormData = null as any;
            this.appControllerReference.updateFinalSubmitButton();
            idScanResultCallback.cancel();
            return;
        }

        this.appControllerReference.onComplete(
            this.latestSessionResult,
            idScanResult
        );
        idScanResultCallback.cancel();
    };

    public onFaceTecSDKCompletelyDone = (): void => {};
}
